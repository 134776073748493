import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { NavLink } from '@mantine/core';
import { memo, useEffect } from 'react';
import { AiFillCrown, AiFillFire, AiFillStar, AiOutlineAreaChart, AiOutlineHome, } from 'react-icons/ai';
import { BsBarChartFill } from 'react-icons/bs';
import { useSnapshot } from 'valtio';
import { AppLink2 } from '~/components/AppLink2';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import QuickStockOrderBox from '~/modules/investment-consultant/orderBox/QuickStockOrderBox';
import { boleStock_store } from './boleStock_store';
import { store } from '~/pages/heineken_template/_private/store';
export const BoleStock_SidePane1 = memo(function BoleStock_SidePane1() {
    const { isPc } = useMedia();
    const currentURL = window.location.href;
    const lastFocusSymbol = useSnapshot(boleStock_store).userLastFocusSymbol;
    const changePage = useSnapshot(boleStock_store).changePage;
    const charting = useSnapshot(store).charting;
    useEffect(() => {
        if (lastFocusSymbol !== charting.symbol && changePage === true) {
            store.charting.changeSymbol(lastFocusSymbol);
            /** 如果有跳轉頁面行為 變更完商品 將觸發關閉 */
            boleStock_store.changePage = false;
        }
    }, [charting.symbol, lastFocusSymbol]);
    return (<styleds.container>
      <span css={css `
          ${fill_horizontal_cross_center};
          width: 100%;
          height: 42px;
          font-size: 22px;
          font-weight: bold;
          padding: 0 8px;
          background-color: #616366;
          border-radius: 4px;
        `}>
        <img css={css `
            width: 56px;
            height: 40px;
          `} src={`/bole/favicon.png`}/>
        籌碼贏家系統
      </span>
      <AppLink2 href={'/bole_stock'}>
        <NavLink active={currentURL.includes('/bole_stock') ||
            currentURL === 'https://bole-stock.moneycom.in/' ||
            currentURL === 'https://dev.bole-stock.moneycom.in/'} description={'威力指標看盤'} label='籌碼看盤' 
    //color='gray'
    variant='filled' icon={<AiOutlineHome />}/>
      </AppLink2>
      <AppLink2 href={'/bole_stock/high-weight-stock'}>
        <NavLink active={currentURL.includes('/high-weight-stock')} description={'前二十大權值股報價'} label='權值股' 
    //color='gray'
    variant='filled' icon={<BsBarChartFill />}/>
      </AppLink2>
      <AppLink2 href={'/bole_stock/user-stock'}>
        <NavLink active={currentURL.includes('/user-stock')} description={'用戶自選股報價'} label='自選股' 
    //color='gray'
    variant='filled' icon={<AiFillStar />}/>
      </AppLink2>
      <AppLink2 href={'/bole_stock/popular-stock'}>
        <NavLink active={currentURL.includes('/popular-stock')} description={'熱門即時排行'} label='熱門股' 
    //color='gray'
    variant='filled' icon={<AiFillFire />}/>
      </AppLink2>
      <AppLink2 href={'/bole_stock/category-trend-chart'}>
        <NavLink active={currentURL.includes('/category-trend-chart')} description={'產業類股當日走勢'} label='類股監控' 
    //color='gray'
    variant='filled' icon={<AiOutlineAreaChart />}/>
      </AppLink2>
      <AppLink2 href={'/bole_stock/popular-industry'}>
        <NavLink active={currentURL.includes('/popular-industry')} description={'近期熱門細產業'} label='熱門產業' 
    //color='gray'
    variant='filled' icon={<AiFillCrown />}/>
      </AppLink2>
      {isPc && (<div css={css `
            width: 100%;
            height: 120px;
            border-radius: 4px;
            background-color: #444444;
            padding: 4px;
            align-self: flex-end;
            margin-top: auto;
          `}>
          <QuickStockOrderBox.DispalySmall />
        </div>)}
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #2b2b2b;
    border-right: 1px solid #676767;
    //border-radius: 4px;
    gap: 4px;
    padding: 4px;
    & > * {
      width: 100%;
    }
  `,
    pageItem: styled.div ``,
};
