import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const bole_rsi_dark = createIndicator({
    displayName: '主力籌碼',
    id: 'bolersi',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const f_0 = (e) => {
                return this.PineJS.Std.max(e, 0);
            };
            const f_1 = (e) => {
                return -this.PineJS.Std.min(e, 0);
            };
            const f_2 = (e, t) => {
                return this.PineJS.Std.eq(e, 0)
                    ? 100
                    : this.PineJS.Std.eq(t, 0)
                        ? 0
                        : 100 - 100 / (1 + t / e);
            };
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const i = this.PineJS.Std.close(this._context);
            const r = 12;
            const o = this._context.new_var(i);
            const s = this.PineJS.Std.change(o);
            const a = f_0(s);
            const l = this._context.new_var(a);
            const c = this.PineJS.Std.rma(l, r, this._context);
            const u = f_1(s);
            const h = this._context.new_var(u);
            const d = this.PineJS.Std.rma(h, r, this._context);
            const colorIndex = f_2(d, c) - 50 > 0 ? 0 : 1;
            return [f_2(d, c) - 50, colorIndex];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        plots: [
            {
                id: 'vol',
                type: 'line',
            },
            {
                id: 'volumePalette',
                type: 'colorer',
                target: 'vol',
                palette: 'volumePalette',
            },
        ],
        palettes: {
            volumePalette: {
                colors: {
                    0: {
                        name: '多方趨勢',
                    },
                    1: {
                        name: '空方趨勢',
                    },
                },
            },
        },
        defaults: {
            //樣式調整
            styles: {
                //--
                plot_0: {
                    transparency: 0,
                    visible: !0,
                },
                //--
                vol: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#02aa22',
                },
            },
            //填充區域
            palettes: {
                volumePalette: {
                    colors: {
                        0: {
                            color: '#ff1111',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#11ff11',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
    },
});
